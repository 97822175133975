* {
    box-sizing: border-box;
}

.App {
    height: calc(100vh - 80px);
}

a {
    text-decoration: none;
    color: inherit;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
}