.toastContainer {
  position: absolute;
  z-index: 9999;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  left: 50%;
  transform: translateX(-50%);
  padding: 32px;
  min-width: 300px;
  transition: all 0.2s;
}

.open {
  opacity: 1;
  top: 80px;
}

.hide {
  user-select: none;
  pointer-events: none;
  opacity: 0;
  top: 60px;
}
